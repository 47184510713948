<template>
  <cms-content :id="id" class="text-left"/>
</template>

<script>
import CmsContent from '@/components/CmsContent'

export default {
  name: 'ContactUs',
  data () {
    return {
      id: 'contact-us-header'
    }
  },
  components: {
    CmsContent
  }
}
</script>
